<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <b-loading :is-full-page="true" :active="!content"/>

            <div class="level-item">
                <h1 class="title is-1 page-title">CONTACT US</h1>
            </div>

            <contact-us-box></contact-us-box>

        </div>
    </transition>
</template>

<script>
    import ContactUsBox from "@/components/content/ContactUsBox.vue";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        components: {
            ContactUsBox
        },
        name: "ContactUs",
        mixins: [ contentAPI, page ],
    }
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
        border-bottom: 3px $c2g_orange solid;
        padding-bottom: 8px;
    }
    .level-item {
        padding: 20px 0 40px 0;
    }

    span {
        color: $c2g_orange;
    }

    /deep/ {
        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
</style>